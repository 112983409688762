import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/Layout'
import Seo from "../components/Seo";

const Page = ({ data }) => {
  const { wpPage: page } = data;

  function renderBg() {
      return (
          <h1 className="page-heading">{page.title}</h1>
      )
  }

  return (
    <Layout>
        <Seo seo={page.seo} />
        {renderBg()}
        <div className="container w-5/6 lg:w-full">
            <div className="py-20 text-lg">
                <div dangerouslySetInnerHTML={{__html: page.content}} />
            </div>
        </div>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ContentById($id: String!) {
    wpPage(id: { eq: $id }) {
        seo {  
          canonical
          title
          metaDesc
          focuskw
                
      }
      uri
      title
      content
          acf_content {
acfImage {
 id
            mediaItemUrl
            localFile {
              publicURL
                  childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
              }
            }
}
}
    }
  }
`;
